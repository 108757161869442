import "../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import { Context } from '../context/Context'
import { client } from '../context/client'
import DarkFooter from "../components/Footers/DarkFooter.js";
import MyPDF3 from './BROCHURE.pdf';
import MyPDF5 from './BROCHURE2.pdf';
import MyPDF4 from './enflate_poster.pdf';
import Banner1 from './ENFLATE_BANNER_VERSION1.pdf';
import Banner2 from './ENFLATE_BANNER_VERSION2.pdf';
import icon from './pdficon2.png'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import flyer from "./Local Impact of ENFLATE at Appenzell - Switzerland.pdf"


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";
import React, { useEffect, useState } from 'react'



function Outcomes() {
  const [deliverables, setDeliverables] = useState([])
  const [promomaterial, setPromomaterial] = useState([])


  useEffect(() => {
    const getAllEntries = async () => {
      try {
        await client.getEntries({content_type:'deliverable'}).then((entries) => {
          console.log(entries,'hhhhh')
          setDeliverables(entries)
        })
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
      }
    };
    const getAllEntries2 = async () => {
      try {
        await client.getEntries({content_type:'promoMaterial'}).then((entries) => {
          console.log(entries,'hhhhh')
          setPromomaterial(entries)
        })
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
      }
    };
    getAllEntries()
    getAllEntries2()
  }, [])
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='publications'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#3a1772',marginTop:'50px'}}>Publications</h2>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography id='typ'>
            Banners
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <Container>
            <Row>
            
              <ul style={{listStyle:'auto'}}>
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a id='banner' href={Banner1} download="Enflate Banner 2023 v1.pdf">Banner 2023 v1 <img style={{width:'22px'}} src={icon}></img></a>
              </li> 
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a id='banner' href={Banner2} download="Enflate  Banner 2023 v2.pdf">Banner 2023 v2 <img style={{width:'22px'}} src={icon}></img></a>
              </li> 
            
          </ul>
            </Row>
            </Container>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography id='typ'>Conference Presentation and Posters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography id='typ'>
          Invited Talks and Colloquia
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            
          </Typography>
        </AccordionDetails>
      </Accordion>
              </Col>
            </Row>
            </Container>
            </section>
            <section id='deliverables'>
        <div className="section section-team text-center" style={{backgroundColor:'#3a1772'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#ededed'}}>Public Deliverables</h2>
             
         
              </Col>
              {deliverables?.items?.map((post) => (
              <section  key={post.sys.id}>
                 <a id='newsletter' href={post.fields.publDeliverable.fields.file.url} download="Project_Manager_Handbook.pdf"><img style={{width:'22px',float:'left',marginRight:'10px'}} src={icon}></img><h1 style={{textAlign:'left',color:'orange', fontSize:'18px'}} >{post.fields.publDeliverable.fields.title}</h1></a>
               
               <p style={{textAlign:'left',color:'whitesmoke',textAlign:'justify',fontSize:'14px'}}>{post.fields.publDeliverable.fields.description}</p>
              </section>
            ))}
            </Row>
            </Container>
        </div>
        </section>
        <section id='material'>
        <div className="section section-team text-center" style={{backgroundColor:'#ededed'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#3a1772'}}>Promotional Material</h2>
              </Col>
              <ul style={{listStyle:'auto'}}>
              {promomaterial?.items?.map((post) => ( 
                <li key={post.sys.id} style={{textAlign:'left',minWidth:'500PX',paddingBottom:'4px'}}>
              <a id='newsletter' href={post.fields.pdf.fields.file.url} >{post.fields.description} <img style={{width:'22px'}} src={icon}></img></a>
              </li>  ))}

          </ul>
            </Row>
            </Container>
        </div>
        </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default Outcomes;
